<template>
    <div id="MarketingTools_coupon">
        <div class="screen">
            <div class="screen_left">
                <el-button type="primary" size="small" @click="toPage('add')">创建优惠券</el-button>
            </div>
            <div class="screen_right">
                <div>
                    <span>优惠券名称:</span>
                    <!-- @change="changeScreen" @input="changeScreen"  -->
                    <el-input v-model="search_value" placeholder=" " size="small" style="width: 160px;"></el-input>
                </div>
                <div>
                    <span>优惠券状态:</span>
                    <el-select v-model="value" placeholder=" " size="small" style="width: 80px;">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <span>有效期:</span>
                    <!-- @change="changeScreen" @input="changeScreen" -->
                    <el-date-picker v-model="seach_times" style="width: 360px;" size="small" type="daterange"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </div>

                <el-button type="primary" size="small" @click="query">查询</el-button>
                <el-button size="small" @click="reset">重置</el-button>

                <!-- <el-button type="text" size="small">查看筛选条件</el-button> -->
            </div>
        </div>
        <div class="table_Container" :style="{ 'height': maxHeight_TableContainer + 'px' }">
            <el-table :data="tableData" style="width: 100%" :max-height="maxHeight_Table"
                :header-cell-style="{ background: '#F8FBFF' }">
                <el-table-column prop="name" label="优惠券名称" align="center"></el-table-column>
                <el-table-column prop="type" label="有效时间" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.start_date }}-{{ scope.row.end_date }}
                    </template>
                </el-table-column>
                <el-table-column label="状态" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.status == 1 ? '启用' : '禁用' }}
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="类别" align="center"></el-table-column>
                <el-table-column prop="describe" label="优惠内容" align="center"></el-table-column>
                <el-table-column prop="num" label="发放量" align="center"></el-table-column>
                <el-table-column prop="use_num" label="使用量" align="center"></el-table-column>
                <el-table-column prop="stock" label="剩余量" align="center"></el-table-column>
                <el-table-column prop="use_rate" label="使用率" align="center"></el-table-column>
                <el-table-column prop="name" label="操作" align="right" width="120">
                    <template slot-scope="scope">
                        <el-button type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button type="text" :style="{ 'color': scope.row.status == 1 ? '#FE2F2F' : '' }"
                            @click="handleChangeStatus(scope.$index, scope.row)">
                            <span v-if="scope.row.status == 1">强制结束</span>
                            <span v-if="scope.row.status == 0">开启活动</span>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <pagination :data="pageNation_data" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
                </pagination>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '../../../../../components/PaginationPage/Pagination.vue'

export default {
    components: {
        Pagination,
    },
    data() {
        return {
            maxHeight_TableContainer: window.innerHeight - 180,//表格容器最大高度
            maxHeight_Table: window.innerHeight - 230,//表格最大高度
            tableData: [],
            // 传递分页组件的数据
            pageNation_data: {
                total: 0,
                currentPage: 1,
                currentSize: 10,
                small: true
            },
            search_value: '',
            seach_times: [],
            options: [{
                value: null,
                label: '全部'
            }, {
                value: 0,
                label: '禁用'
            }, {
                value: 1,
                label: '启用'
            }],
            value: ''
        }
    },
    created() {
        this.getList()
    },
    mounted() { },
    methods: {
        // 查询
        query() {
            this.getList()
        },
        // 重置
        reset() {
            this.search_value = null
            this.seach_times = []
            this.value = null
            this.getList()
        },

        // 创建优惠券
        toPage(name) {
            this.$router.push({ path: '/EsportsMember/MarketingTools_coupon_add' })
            // this.$router.push({ path: `/EsportsMember/SetUPPackages?id=${row.id}` })
        },
        // 获取优惠券列表
        getList() {
            let data = {
                name: this.search_value,
                status: this.value,
                start_date: this.seach_times[0] ? this.seach_times[0] : '',
                end_date: this.seach_times[1] ? this.seach_times[1] : '',
                page: this.pageNation_data.currentPage,
                page_rows: this.pageNation_data.currentSize,
            }
            console.log('获取优惠券列表data', data)
            this.my_request('admin/coupon', data).then(res => {
                console.log('获取优惠券列表结果', res)
                let { code, data } = res.data
                if (200 == code) {
                    this.tableData = data.list
                    this.pageNation_data.total = data.total
                }
            })
        },

        // 改变筛选值
        changeScreen() {
            console.log(this.search_value, 'search_value');
            // this.getList()
        },
        // 分页组件事件
        handleSizeChange(val) {
            // 处理每页数量改变的逻辑
            console.log(`每页 ${val} 条`);
            this.pageNation_data.currentSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            // 处理当前页码改变的逻辑
            console.log(`当前页: ${val}`);
            this.pageNation_data.currentPage = val
            this.getList()
        },
        // 编辑
        handleEdit(index, row) {
            console.log(index, row);
            this.$router.push({ path: `/EsportsMember/MarketingTools_coupon_add?edit_id=${row.id}` })
        },
        // 点击强制结束
        handleChangeStatus(index, row) {
            console.log(index, row);
            let data = {
                id: row.id,
                status: row.status == 1 ? 0 : 1
            }
            console.log('点击强制结束data', data)
            this.my_request('admin/coupon/status', data, 'PUT').then(res => {
                console.log('点击强制结束结果', res)
                if (200 == res.data.code) {
                    this.getList()
                    this.$message.success('状态已更新')
                }
            })
        },
    }
}

</script>

<style lang="scss" scoped>
#MarketingTools_coupon {
    .screen {
        margin-bottom: 20px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .screen_right {
            display: flex;
            align-items: center;
            font-size: 12px;

            span {
                margin-right: 10px;
            }

            .el-button {
                // margin: 0 10px;
            }

            div {
                margin-right: 10px;
            }
        }
    }

    .table_Container {
        position: relative;
        // background-color: antiquewhite;

        .pagination {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}
</style>