<template>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="data.currentPage"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="data.currentSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="data.total"
        :small="data.small"
      >
      </el-pagination>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      data: Object
    },
    data() {
      return {
        paginationData: {
          total: 0,
          currentPage: 1,
          currentSize: 10,
          small: false
        }
      }
    },
    created() {
  },

    methods: {
      handleSizeChange(val) {
        this.paginationData.currentSize = val;
        this.$emit("size-change", val); // 触发事件，将当前每页数量传递给父组件
      },
      handleCurrentChange(val) {
        this.paginationData.currentPage = val;
        this.$emit("current-change", val); // 触发事件，将当前页码传递给父组件
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .pagination {
    width: 100%;
    height: 30px;
    .el-pagination {
      float: right;
    }
  }
  </style>
  